.MuiRating-sizeLarge {
  font-size: 50px !important;

  @media screen and (max-width: 767.98px) {
    font-size: 40px !important;
  }

  @media screen and (max-width: 499.98px) {
    font-size: 35px !important;
  }

  @media screen and (max-width: 449.98px) {
    font-size: 30px !important;
  }

  @media screen and (max-width: 374.98px) {
    font-size: 28px !important;
  }

  @media screen and (max-width: 359.98px) {
    font-size: 26px !important;
  }

  @media screen and (max-width: 339.98px) {
    font-size: 24px !important;
  }
}

.title {
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 600;
}

.button-block {
  margin-top: 50px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}