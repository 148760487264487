.FullPageSpinner {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10000;
  align-items: center;
  justify-content: center;
  display: none;
}

.FullPageSpinner.show {
  display: flex;
}