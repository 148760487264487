html {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    height: 100%;
    width: 100%;
    background-color: rgb(244, 244, 244);
}

header.minimal-header {
    background-color: transparent;
    position: relative;
    a {
        display: block;
        margin: auto;
    }
}

#root .minimal-container {
    min-height: 100vh;
    padding-top: 0;
    background-image: url('./images/minimal-bg.jpg');
    background-size: cover;
    position: relative;
    .StartView-root-4 {
        padding-top: 0;
    }
    .ClientFeedback-root-25 {
        padding-top: 0;
    }
}

* {
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    display: none;
}
