.error-popup {
    &__close {
        display: flex;
        justify-content: flex-end;
    }

    &__decor {
        position: absolute;
        bottom: -32px;
        left: 0;
        width: 100%;
        background-color: #d7d7d7;
        height: 135px;
        opacity: 0.33;
        transform: skewY(-5deg);
    }

    &__container {
        position: relative;
        z-index: 2;
    }

    &__item:last-child {
        padding: 30px 0 0 20px;
    }

    &__title.MuiTypography-h2 {
        padding-bottom: 10px;
        font-weight: bold;
    }

    &__text {
        max-width: 73%;
        display: block;
        @media screen and (max-width: 768px) {
            max-width: 100%;
        }
    }

    &__back {
        font-size: 12px;
        padding: 30px 0 10px 0;
        a {
            color: #0441ca;
        }
    }

    &__btn {
        max-width: 300px;
        width: 100%;
        height: 50px;
        margin: 50px auto;
        display: block;
        font-size: 18px;
    }

    .MuiDialogContent-root {
        padding: 10px 50px 50px 60px;
        @media screen and (max-width: 768px) {
            padding: 10px 10px 50px 10px;
            text-align: center;
        }
    }
    .MuiPaper-rounded {
        border-radius: 10px;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
        background-image: linear-gradient(to top, #ededed 0%, #ffffff 100%);
        position: relative;
        overflow: hidden;
    }

    .MuiDialog-paperWidthSm {
        max-width: 900px;
    }

    img {
        max-width: 100%;
        height: 250px;
    }
}
