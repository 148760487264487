$margin: 10px;

.MuiDialog-paper {
  margin: $margin !important;
}

.MuiDialog-paperFullWidth {
  width: calc(100% - #{$margin * 2}) !important;
}

.MuiTypography-root.MuiTypography-h6 {
  font-size: 18px;

  @media screen and (max-width: 499.98px) {
    font-size: 16px !important;
  }
}