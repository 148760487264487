#chatContainer {
    position: absolute;
    /*z-index: 0;*/
    width: 100%;
    height: 100%;

    z-index: 9999;
    background-color: white;
}

#chatToolbar {
    height: 30px;
    background-color: #e31837;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    padding-top: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #ffffff;
}

#closeButton {
    position: absolute;
    right: 0;
    top: 0px;
}

#chatComponent {
    background-color: rgb(244, 244, 244);
    position: absolute;
    // z-index: 99999;
    z-index: 15;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    border-radius: 20px;
    box-shadow: inset 0px 0 1px rgba(0, 0, 0, 0.25);
}

.message-wrap {
    padding: 0 15px;
    height: calc(100% - 80px);
    overflow: auto;
}

.message {
    position: relative;
    padding: 7px 0;
}

.user-img {
    position: absolute;
    border-radius: 45px;
    width: 60px;
    height: 60px;
    top: 15px;
}

.msg-detail {
    //width: calc(100% - 65px);
    width: 100%;
    display: inline-block;
}

.msg-detail p {
    margin: 0;
    font-size: 15px;
}

.msg-info p {
    font-size: 0.8em;
    color: #000000;
    font-weight: 600;
    //font-style: italic;
}

.msg-content {
    position: relative;
    margin-top: 5px;
    border-radius: 10px 0px 10px 10px;
    padding: 8px;
    color: #000000;
    width: auto;
    max-width: 80%;
}

//span.triangle {
//    border-radius: 2px;
//    height: 8px;
//    width: 8px;
//    //top: 12px;
//    top: 0px;
//    display: block;
//    -webkit-transform: rotate(45deg);
//    transform: rotate(45deg);
//    position: absolute;
//}

.text {
    word-break: break-all;
    color: #000000;
}

/* Start message from other user */

.message > .left .msg-detail .msg-info {
    text-align: left;
}

.message.left .msg-detail {
    //padding-left: 65px;
}

.message.left .user-img {
    left: -5px;
    border: 1px solid #f0f0f094;
}

.message.left .msg-detail .msg-content {
    background-color: rgb(225, 225, 225);
    float: left;
    border-radius: 0px 10px 10px 10px;
}

//.message.left .msg-detail .msg-content span.triangle {
//    background-color: #ccc;
//    border-bottom-width: 0;
//    border-left-width: 0;
//    left: -5px;
//}

/* End message from other user */

/* Start my messages */

.message.right .msg-detail .msg-info {
    text-align: right;
}

.message.right .user-img {
    right: -5px;
    border: 1px solid #e33638;
}

.message.right .msg-detail .msg-content {
    background-color: #e33638;
    float: right;
}

.message.right .msg-detail .msg-content span.triangle {
    background-color: #e33638;
    border-bottom-width: 0;
    border-left-width: 0;
    right: -5px;
}

.message.right .text {
    color: #ffffff;
}

/* End my messages */

#messageInput {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #ffffff;
    text-align: center;
    padding: 10px 0px;
    height: 30px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: inset 0px 0 1px rgba(0, 0, 0, 0.25);
}

#messageInput input {
    width: 90%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    margin-left: -6%;
    color: #000000;
}

#sendButton {
    background-color: #e31837;
    position: absolute;
    right: 10px;
    //   top: 0;
    //   bottom: 0;
    bottom: calc(50% - 22px);
    margin: auto;
    border: 1px solid #e31837;
    box-shadow: none !important;
}

#sendButton {
    margin-left: 3px !important;
    margin-bottom: 2px !important;
}

//::-webkit-scrollbar {
//    width: 8px;
//}
//
//::-webkit-scrollbar-thumb {
//    background-color: #6b6b6b;
//}
//
//.chatComponentLight ::-webkit-scrollbar-thumb {
//    background-color: #eeeeee !important;
//}
